@tailwind components;
@tailwind utilities;

$card-bg-color: #EAECEB;
$card-border-color: #31313a;

$card-level2-bg-color: #25252c;
$card-level2-order-color: #383842;

$card-level3-bg-color: #3b3b44;
$card-level3-order-color: #4c4c55;

$bg-color: #f8f8f8;
$font-color: #212121;
$main-color: #7b6af6;
$main-gray: #797e8b;

$sidebar-bg-color: #1f1f22;
$sidebar-width: 510px;
$sidebar-collapsed-width: 0px;

// @import 'react-pro-sidebar/dist/scss/styles.scss';
@import '@fortawesome/fontawesome-free/css/fontawesome.css';
@import '@fortawesome/fontawesome-free/css/solid.css';
@import 'reactjs-popup/dist/index.css';
@import 'react-tooltip/dist/react-tooltip.css';
@import 'react-toastify/dist/ReactToastify.css';

////////////////// START NOTION IMPORTS //////////////////
// core styles shared by all of react-notion-x (required)
@import 'react-notion-x/src/styles.css';

// // used for code syntax highlighting (optional)
// @import 'prismjs/themes/prism-tomorrow.css';

// // used for rendering equations (optional)
// @import 'katex/dist/katex.min.css';
////////////////// END NOTION IMPORTS //////////////////

@font-face {
  font-family: 'Inter';
  src: local('Inter'), url(./Fonts/Inter-VariableFont_slnt,wght.ttf) format('truetype');
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  margin: 0px;
  overflow-y: clip;
}

#root {
  height: 100%;
  width: 100%;
}

canvas {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.dropdown-cell {
  display: flex;
  justify-content: center;
  margin: 0px;
}

.dropdown-button {
  cursor: pointer;
  color: white;
  transition-duration: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown-button:hover {
  color: #adadad;
}

.dropdown-selected {
  color: rgb(126, 108, 246);
}

.dropdown-selected:hover {
  color: rgb(95, 82, 177);
}

.table-cell {
  display: flex;
  justify-content: center;
}

.table-cell-value {
  padding: 4px 10px;
  border-radius: 5px;
  border: 1px solid $main-gray;
  background-color: rgba(121, 126, 139, 0.25);
}

.table-cell-value-alert {
  border: 1px solid rgb(212, 82, 110);
  background-color: rgba(212, 82, 110, 0.25);
}

// .app {
//   display: flex;
//   position: relative;
// }

// .sidebar {
//   box-shadow: 0 0 10px lighten($sidebar-bg-color, 10%);
// }

.timebar {
  width: '100%';
  height: '100px';
  position: 'absolute';
  bottom: '0px';
}

.bops-logo {
  background-color: $card-bg-color;
  position: fixed;
  z-index: 1009;
  top: 20px;
  right: 20px;
  width: 52px;
  height: 52px;
  border-radius: 26px;
  box-shadow: 0 0 20px lighten($main-color, 1%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.bops-logo img {
  height: 40px;
  width: auto;
}

.bops-logo-button:hover {
  opacity: 0.7;
}

.select-row {
  position: fixed;
  top: 30px;
  left: 535px;
  z-index: 1009;
  display: inline-flex;
}

.tree-toggle-button {
  border: solid 2px;
  border-radius: 50px;
  width: 40px !important;
  height: 40px !important;
  margin: 5px;
  float: right;
  box-shadow: 0 0 4px lighten(#fff, 1%);

}

.tree-toggle-button svg {
  width: 25px !important;
  height: 25px !important;
}

.select {
  height: 38px;
  width: 140px;
  margin-right: 20px;
}

.app main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.top {
  background-color: var(--main-bg-color);
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.search-card {
  position: fixed;
  left: 20px;
  top: 20px;
}

.search-card img {
  height: 40px;
  width: auto;
  margin-right: 5px;
}

.fact-sheet-card {
  position: fixed;
  top: 100px;
  left: 20px;
  z-index: 200;
  transition: visibility 0s, opacity 0.5s linear;
}

.fact-sheet {
  padding: 10px 30px;
  color: white;
  position: relative;
  min-height: 300px;
}

.fact-id {
  font-weight: 800;
  margin-top: 5px;
  margin-bottom: 35px;
}

.fact-title {
  margin-bottom: 2px;
}

.fact-text {
  margin-top: 0px;
  margin-left: 5px;
  max-width: 45%;
}

.component-list {
  margin: 2px 5px;
}

.material-detail-block {
  min-height: 200px;
}

.process-title {
  padding-left: 25px;
  margin-bottom: -15px;
}

.process-group-bar {
  margin-top: 35px;
}

.process-stacked-bar {
  margin-top: 0px;
}

.show {
  visibility: visible;
  opacity: 1;
}

.hidden {
  visibility: hidden;
  opacity: 0;
}

.card {
  background-color: $card-bg-color;
  border-radius: 40px;
  width: auto;
  height: auto;
  z-index: 2000;
  padding: 5px 10px;
  color: rgb(1, 18, 51);
  box-shadow: 0 0 25px lighten($card-bg-color, 10%);
}

.inline-card {
  display: inline-flex;
  align-items: center;
}

.toggle-button {
  height: 48px;
  width: 48px;
  cursor: pointer;
  color: white;
  transition-duration: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.toggle-button:hover {
  color: #adadad;
}

.toggle-selected {
  color: rgb(126, 108, 246);
}

.toggle-selected:hover {
  color: rgb(95, 82, 177);
}

.select-search-bar {
  width: 300px;
  margin: 2px 10px 0px 5px;
}

.nav-header {
  display: inline-flex;
  margin-left: 10px;
}

.nav-header-logo {
  width: 48px;
  height: fit-content;
  margin: auto 12px;
}

.nav-header-title {
  color: rgb(126, 108, 246);
  font-size: 36px;
  font-weight: bolder;
}

.footer {
  display: inline-flex;
  margin-left: 20x;
}

.logout {
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  display: inline-flex;
  align-items: center;
  margin-left: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.logout img {
  height: 40px;
}

.logout h3 {
  color: #adadad;
  font-size: 48;
  margin-left: 8px;
}

.search-bar {
  width: 75%;
}

.material-kpi {
  margin-top: 25px;
  padding-top: 35px;
  padding-bottom: 35px;
  border-top: solid 1px rgba(173, 173, 173, 0.2);
  border-bottom: solid 1px rgba(173, 173, 173, 0.2);
}

.kpi-select {
  display: flex;
}

.kpi-block {
  margin: 20px 0px;
  width: 100%;
  display: inline-flex;
  justify-content: space-around;
}

.kpi-title-row {
  display: flex;
  justify-content: space-around;
}


.kpi-title-value {
  font-weight: bold;
  margin-bottom: 0px;
}

.material-location-select {
  width: 200px;
  margin: 0px 50px 30px 25px;
}

// [START] Styles for opean ai widget

.css-hkjqq3 {
  overflow: hidden !important;
}

.css-1eyzixu-MuiTypography-root 
{
  font-family: 'Inter' !important;
}

.css-iesgy6-MuiTypography-root
{
  font-family: 'Inter' !important;
  font-size: 15px !important;
}

.css-tubuvk-MuiTypography-root
{
  font-family: 'Inter' !important;
}

.css-1qxbla7-MuiTypography-root 
{
  font-family: 'Inter' !important;
  font-size: 0.8rem !important;
}

.css-19uac2t  {
  background-color: #E2E2E2 !important;
  font-family: 'Inter' !important;
}

.css-ozfrzi {
  background-color: #E2E2E2 !important;
}

.css-1wnjrgw {
  background-color: #E2E2E2 !important;
}

.css-lpw1b0 {
  font-family: 'Inter' !important;
}

.css-1c268dr-MuiFormControl-root-MuiTextField-root .MuiInputBase-input {
  font-family: 'Inter' !important;
}

.css-16765pq {
  font-family: 'Inter' !important;
}

.css-abp51j {
  font-family: 'Inter' !important;
}

.css-poorx3 {
  font-family: 'Inter' !important;
}

.css-1wnjrgw {
  font-family: 'Inter' !important;
}

// [END] Styles for opean ai widget